<template>
  <div class="attestation">
    <div class="attTitle">{{ $t.attestations }}</div>

    <div class="attImgs" v-for="(item, index) in attestationList" :key="index">
      <div class="attLabel" v-if="item.OrderBy == 999">
        {{ item.OtherAttestationDescribe }}
      </div>
      <div class="attLabel" v-else>
        {{ item.AttestationName }}
      </div>
      <img
        class="attImg"
        v-for="(child, idx) in item.AttestationPhoto"
        :key="idx"
        :src="child && imgUrlFilter(child)"
        @click="imagePreview(item.AttestationPhoto, idx)"
      />
    </div>

    <div class="sub" @click="sub">{{ $t.confirm }}</div>
  </div>
</template>
<script>
export default {
  name: "Attestation",
  props: ["attestationList"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    imagePreview(images, index) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.picturePreview, {
        pictureList: images,
        startPosition: index,
      });
    },
    sub() {
      this.$emit("showAttestations");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.attestation {
  margin: 5vw;
  .attTitle {
    font-size: 16px;
    font-weight: bold;
  }
  .attImgs {
    margin-top: 5vw;
    .attLabel {
      font-size: 14px;
    }
    .attImg {
      margin-top: 3vw;
      margin-right: 3vw;
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
  .sub {
    width: 45%;
    margin: 0 auto;
    color: #ffffff;
    background-color: #01aaa3;
    border-radius: 50px;
    line-height: 40px;
    text-align: center;
    margin-top: 10vw;
    margin-bottom: 10vw;
    font-size: 14px;
  }
}
</style>
