<template>
  <div class="att">
    <div class="attImgs">
      <div class="attLabel">{{ $t.att1 }}</div>
      <img
        class="attImg"
        v-for="(image, index) in attList.att1"
        :key="index"
        :src="image && imgUrlFilter(image)"
        @click="imagePreview(attList.att1, index)"
      />
    </div>

    <div class="attImgs">
      <div class="attLabel">{{ $t.att2 }}</div>
      <img
        class="attImg"
        v-for="(image, index) in attList.att2"
        :key="index"
        :src="image && imgUrlFilter(image)"
        @click="imagePreview(attList.att2, index)"
      />
    </div>

    <div class="attImgs">
      <div class="attLabel">{{ $t.att3 }}</div>
      <img
        class="attImg"
        v-for="(image, index) in attList.att3"
        :key="index"
        :src="image && imgUrlFilter(image)"
        @click="imagePreview(attList.att3, index)"
      />
    </div>

    <div class="sub" @click="sub">{{ $t.confirm }}</div>
  </div>
</template>
<script>
export default {
  name: "Att",
  props: ["attList"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    imagePreview(images, index) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.picturePreview, {
        pictureList: images,
        startPosition: index,
      });
    },
    sub() {
      this.$emit("showAtt");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.att {
  margin: 5vw;
  .attImgs {
    .attLabel {
      font-size: 14px;
    }
    margin-top: 5vw;
    .attImg {
      margin-top: 3vw;
      margin-right: 3vw;
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
  .sub {
    width: 45%;
    margin: 0 auto;
    color: #ffffff;
    background-color: #01aaa3;
    border-radius: 50px;
    line-height: 40px;
    text-align: center;
    margin-top: 10vw;
    margin-bottom: 10vw;
    font-size: 14px;
  }
}
</style>
