<template>
  <div class="details">
    <div class="swiperBox">
      <details-swiper
        :swiperList="swiperList"
        :videoUrl="productVideo"
        ref="detailsSwiper"
      ></details-swiper>
      <!-- <figure class="back">
        <img class="backIcon" src="~assets/img/details/icon_detail_back.png" />
      </figure>-->
    </div>
    <details-info
      :loadProduct="loadProduct"
      :attList="attList"
      :attestationList="attestationList"
      @showAttestations="showAttestations"
      @showAtt="showAtt"
      @showPopup="showPopup"
    ></details-info>
    <lists :productList="productList" class="lists"></lists>
    <div class="shopBox">
      <van-button
        plain
        round
        type="default"
        @click="getDetailsInfo(loadProduct.CustomerId)"
        >{{ $t.meta.viewShop }}</van-button
      >
    </div>
    <store-operation
      :productNumber="productNumber"
      :loadProduct="loadProduct"
      @reload="reload"
    ></store-operation>

    <van-popup
      v-model="attestationShow"
      position="bottom"
      round
      :style="{ maxHeight: '90%' }"
    >
      <attestations
        @showAttestations="showAttestations"
        :attestationList="attestationList"
      ></attestations>
    </van-popup>

    <van-popup
      v-model="attShow"
      position="bottom"
      round
      :style="{ maxHeight: '90%' }"
    >
      <att @showAtt="showAtt" :attList="attList"></att>
    </van-popup>

    <van-popup
      v-model="skuShow"
      position="bottom"
      round
      :style="{ maxHeight: '75%' }"
    >
      <sku @showPopup="showPopup" :loadProduct="loadProduct"></sku>
    </van-popup>
  </div>
</template>
<script>
import Lists from "@main/common/Lists";
import DetailsSwiper from "./components/DetailsSwiper.vue";
import DetailsInfo from "./components/DetailsInfo";
import StoreOperation from "./components/StoreOperation";
import Attestations from "./components/Attestations";
import Att from "./components/Att";
import Sku from "./components/Sku";
import Vue from "vue";
import { Button, Popup } from "vant";
Vue.use(Popup).use(Button);
export default {
  name: "Details",
  components: {
    Lists,
    DetailsSwiper,
    DetailsInfo,
    StoreOperation,
    Attestations,
    Att,
    Sku,
  },
  data() {
    return {
      productNumber: {},
      productList: [], //推荐产品
      loadProduct: {}, //产品详情
      swiperList: [], //轮播图
      productVideo: null, // 商品视频
      skuShow: false,
      //Logo 授权书 注册证
      attList: null,
      attShow: false,
      //商品认证
      attestationList: [],
      attestationShow: false,
    };
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (this.$route.query && to && from) {
        //判断id是否有值
        //调数据
        const { ProductNumber } = to.query;
        this.productNumber = ProductNumber;
        ProductNumber && this.getProductInfoByCustomer();
      }
    },
  },
  beforeMount() {
    const { ProductNumber } = this.$route.query;
    this.productNumber = ProductNumber;
  },
  mounted() {
    // 获取详情数据
    this.getProductInfoByCustomer();
  },
  methods: {
    reload(success) {
      if (success) this.getProductInfoByCustomer();
    },
    videoUrlFilter(value) {
      return this.$api.formatVideoUrl(value);
    },
    showAttestations() {
      this.attestationShow = !this.attestationShow;
    },
    showAtt() {
      this.attShow = !this.attShow;
    },
    showPopup() {
      this.skuShow = !this.skuShow;
    },
    // 获取商品详情
    getProductInfoByCustomer() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        productNumber: this.productNumber,
        BPStatus: 0,
      };
      this.$api.product
        .loadProductInfoByCustomer(param)
        .then((res) => {
          const data = res.data;
          this.productList = data.CustomerProductList;
          //this.swiperList = data.ListViewAdverts;
          if (data.LoadProduct && data.LoadProduct.ProductNumber) {
            this.loadProduct = data.LoadProduct;
            this.swiperList = data.LoadProduct.ProductPhoto.split("|");
            this.productVideo = this.videoUrlFilter(
              data.LoadProduct.ProductVideo
            );
            this.getAttestationData(data.LoadProduct);
            this.getAttData(data.LoadProduct);
            this.$nextTick((_) => {
              this.$refs.detailsSwiper.onloadVideos();
            });
          } else {
            this.$commonEnv.commonAction(this.$commonEnv.actionType.pop, {});
            setTimeout(() => {
              this.$commonEnv.commonAction(
                this.$commonEnv.actionType.showDialog,
                {
                  msg: this.$t.commodityDeleted,
                }
              );
            }, 500);
          }
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    getAttestationData(data) {
      if (
        data.ProductAttestationList &&
        data.ProductAttestationList.length > 0
      ) {
        var items = [];
        for (var attestation of data.ProductAttestationList) {
          var item = attestation;
          item.AttestationPhoto = item.AttestationPhoto.split("|");
          items.push(item);
        }
        this.attestationList = items;
      }
    },
    getAttData(data) {
      if (
        data.BrandLogo &&
        data.BrandAuthorizationLetter &&
        data.TrademarkRegistrationCertificate
      ) {
        this.attList = {
          att1: data.BrandLogo.split("|"),
          att2: data.BrandAuthorizationLetter.split("|"),
          att3: data.TrademarkRegistrationCertificate.split("|"),
        };
      }
    },
    //跳转详情页
    getDetailsInfo(customerId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "drawableInfo",
        name: "DrawableInfo",
        query: [
          {
            name: "CustomerId",
            value: customerId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.details {
  font-size: 28px;
  width: 100%;
  padding-bottom: 80px;
  .swiperBox {
    width: 100%;
    position: relative;
    .back {
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 38px;
      margin-top: 20px;
      background-color: #ffffff;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      border-radius: 26px;
      margin-left: 20px;
      .backIcon {
        width: 100%;
      }
    }
  }

  .shopBox {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    .van-button {
      width: 90%;
      color: #999;
    }
  }

  .lists {
  }
}
</style>
